import React from "react";
import classNames from "classnames";

const ProfileJobTitle = ({
  profile,
  disabled,
  className,
  children,
  ...props
}) => {
  if (!profile) return null;

  const { jobTitle, organisationName } = profile;

  const css = classNames("profile-job-title", { disabled }, className);
  return (
    <section className={css} {...props}>
      {organisationName && <span className="title">{organisationName}</span>}
      {organisationName && jobTitle ? (
        <span style={{ padding: "0 0.5em" }}>&middot;</span>
      ) : null}
      {jobTitle && <span className="title">{jobTitle}</span>}
      {children}
    </section>
  );
};

export default ProfileJobTitle;
