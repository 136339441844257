import { apiPut } from "@redriver/cinnamon";
import { DateTime } from "luxon";

const NAMESPACE = "PROFILE/CONFIRM_PRIVACY_SETTINGS";

const ActionTypes = {
  ConfirmPrivacySettings: `${NAMESPACE}/CONFIRM`,
};

export const confirmPrivacySettings = () => {
  const request = {
    confirmPrivacySettingsUtc: DateTime.utc(),
  };
  return apiPut(ActionTypes.ConfirmPrivacySettings, `profile/privacy`, request);
};
