import { registerLookup } from "@redriver/cinnamon";
import {
  getRegistrationItemTypes,
  getRegistrationItemWidths,
  getRegistrationEventDetails,
  getTravelProviderInfo,
  getMealRequirementOptions,
} from "./actions";
import { MealRequirementOption } from "constants/enums";

export const registrationItemTypesLookup = registerLookup(
  "registrationItemTypesLookup",
  getRegistrationItemTypes
);

export const registrationItemWidthsLookup = registerLookup(
  "registrationItemWidthsLookup",
  getRegistrationItemWidths
);

export const registrationEventDetailsLookup = registerLookup(
  "registrationEventDetailsLookup",
  getRegistrationEventDetails
);

export const travelProviderInfo = registerLookup(
  "travelProviderInfoLookup",
  getTravelProviderInfo
);

export const mealRequirementOptions = registerLookup(
  "getMealRequirementOptions",
  getMealRequirementOptions,
  {
    transform: (data) => {
      // force other option to bottom
      const other = data.find((x) => x.value == MealRequirementOption.Other);
      return [
        ...data.filter((x) => x.value != MealRequirementOption.Other),
        other,
      ];
    },
  }
);
