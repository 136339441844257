import React, { useCallback, useEffect, useRef, useState } from "react";
import { Segment, Button, Icon } from "semantic-ui-react";
import { SideColumn, useResponsive } from "@redriver/cinnamon";
import { ScreenBreakpointNames } from "constants/screenBreakpoints";
import { ColumnGrid } from "components/containers";
import RegistrationItem from "./RegistrationItem";
import { useSelector } from "react-redux";
import {
  getHasParticipantsRegistered,
  getIsEditMode,
  getAllQuestions,
  getAllSectionsFormData,
  getHasPublishedRegistration,
  getParticipantIsVirtualAttendance,
} from "./selectors";
import AddRegistrationItemModal from "./AddRegistrationItemModal";
import { ClickArea } from "components/buttons";
import EditRegistrationGroup from "./EditRegistrationGroup";
import { Message } from "semantic-ui-react";
import { RegistrationItemTypes } from "constants/enums";
import { optionallyFilterSpecialMealRequirements } from "./utils";

const itemMinColSizes = [
  { width: 500, value: 6 },
  { width: 700, value: 3 },
];

const RegistrationGroup = ({
  group,
  eventId,
  participantId,
  onRefresh,
  children,
  isOnLastStep,
  sectionId,
}) => {
  const { screenSize } = useResponsive();
  const shouldStack = screenSize == ScreenBreakpointNames.Mobile;
  const isInEditMode = useSelector(getIsEditMode);
  const isPublished = useSelector(getHasPublishedRegistration);
  const el = useRef();
  const [availableWidth, setAvailableWidth] = useState(null);
  const allQuestions = useSelector(getAllQuestions);
  const isVirtualAttendance = useSelector(getParticipantIsVirtualAttendance);

  const changeSize = useCallback(() => {
    setAvailableWidth(el.current?.clientWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", changeSize);
    return () => window.removeEventListener("resize", changeSize);
  }, []);

  useEffect(() => {
    changeSize();
  }, [group]);

  const minColSize = itemMinColSizes.find(
    (x) => availableWidth <= x.width
  )?.value;

  const priorEventMandatoryFields = group.items.some(
    (x) => x.type == RegistrationItemTypes.Conclusion
  )
    ? allQuestions.filter(
        (x) =>
          !x.hidden &&
          !!x.mandatoryPriorEvent &&
          optionallyFilterSpecialMealRequirements(x, isVirtualAttendance)
      )
    : allQuestions.filter(
        (x) =>
          x.groupId == group.id &&
          !x.hidden &&
          x.mandatoryPriorEvent &&
          optionallyFilterSpecialMealRequirements(x, isVirtualAttendance)
      );

  const allStepFormsState = useSelector(getAllSectionsFormData);
  const completedPriorEventFields =
    priorEventMandatoryFields.length == 0 ||
    priorEventMandatoryFields.every(
      (x) =>
        allStepFormsState[x.sectionId][x.id] !== null &&
        allStepFormsState[x.sectionId][x.id] !== undefined &&
        allStepFormsState[x.sectionId][x.id] !== ""
    );

  const groupItems = group.items.filter((x) => isInEditMode || !x.hidden);

  const content =
    !isInEditMode && !groupItems.length ? (
      <p>This section is not required for this event.</p>
    ) : (
      <ColumnGrid cols={6} className="item-grid" ref={el}>
        {groupItems.map((x) => (
          <RegistrationItem
            key={x.id}
            item={x}
            eventId={eventId}
            participantId={participantId}
            groupId={group.id}
            onRefresh={onRefresh}
            minColSize={minColSize}
            sectionId={sectionId}
          />
        ))}
      </ColumnGrid>
    );

  const addQuestion =
    isInEditMode && !isOnLastStep && !isPublished ? (
      <div className="add-question">
        <SideColumn
          className={shouldStack ? "stacking" : null}
          renderColumn={() => null}
        >
          <ColumnGrid cols={2}>
            <ColumnGrid.Item colsWidth={1}>
              <AddRegistrationItemModal
                as={ClickArea}
                className="add-question-button"
                onSubmitted={onRefresh}
                eventId={eventId}
                groupId={group.id}
              >
                <span>
                  <Icon name="plus" />
                  Add Additional Question
                </span>
              </AddRegistrationItemModal>
            </ColumnGrid.Item>
          </ColumnGrid>
        </SideColumn>
      </div>
    ) : null;

  const priorEventFieldsWarning =
    priorEventMandatoryFields.length > 0 && !completedPriorEventFields ? (
      <Message className="orange template-message" visible>
        <Message.Content>
          The following fields need to be completed prior to the event starting:
          <ul style={{ margin: "0.5em 0 0 0" }}>
            {priorEventMandatoryFields.map((item, i) => (
              <li key={i}>{item.label}</li>
            ))}
          </ul>
        </Message.Content>
      </Message>
    ) : null;

  return (
    <Segment className="registration-group">
      {group.name ? (
        <SideColumn
          className={shouldStack ? "stacking" : null}
          renderColumn={() => (
            <React.Fragment>
              <h3 className="group-header">
                {group.name}
                {isInEditMode && (
                  <div style={{ display: "inline-block" }}>
                    <EditRegistrationGroup
                      group={group}
                      eventId={eventId}
                      onRefresh={onRefresh}
                    />
                  </div>
                )}
              </h3>
              <p className="group-description">{group.description}</p>
              {priorEventFieldsWarning}
            </React.Fragment>
          )}
        >
          {content}
          {children}
        </SideColumn>
      ) : (
        <React.Fragment>
          {priorEventFieldsWarning}
          {content}
          {children}
        </React.Fragment>
      )}

      {addQuestion}
    </Segment>
  );
};

export default RegistrationGroup;
