import React, { useState, useRef, useEffect } from "react";
import { Form, FormBuilder } from "@redriver/cinnamon";
import {
  updateStepFormState,
  setStepValidation,
  setShowValidationErrors,
} from "./actions";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  getSectionFormData,
  getSectionInfo,
  getUnlockedToIndex,
  getIsEditMode,
  getHasParticipantsRegistered,
  getSectionFormValidationState,
  getShowValidationErrors,
  getHasPublishedRegistration,
} from "./selectors";
import { Link, useHistory } from "react-router-dom";
import { EventRoutes } from "constants/routes";
import StepPrevious from "./StepPrevious";
import StepNext from "./StepNext";
import StepSave from "./StepSave";
import RegistrationGroup from "./RegistrationGroup";
import { MessageBox } from "components/containers";
import InfoIcon from "assets/icons/information.svg";
import AddRegistrationItemModal from "./AddRegistrationItemModal";
import { Button } from "semantic-ui-react";
import { addItem } from "./actions";
import CreateNewGroup from "./CreateNewGroup";

const ProgressStep = ({
  step,
  hasNextStep,
  hasPreviousStep,
  onNextStep,
  onPreviousStep,
  eventId,
  participantId,
  saveSectionAction,
  onRefresh,
  disabled,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const formData = useSelector((s) => getSectionFormData(s, step.key));
  const section = useSelector((s) => getSectionInfo(s, step.key));
  const isInEditMode = useSelector(getIsEditMode);
  const isPublished = useSelector(getHasPublishedRegistration);
  const isFirstStep = !hasPreviousStep;
  const isLastStep = !hasNextStep;
  const wrapperEl = useRef();
  const formIsValid = useSelector((s) =>
    getSectionFormValidationState(s, step.key)
  );
  const showErrors = useSelector(getShowValidationErrors);
  const setShowErrors = (isValid) => dispatch(setShowValidationErrors(isValid));

  const saveProps = {
    eventId: eventId,
    participantId: participantId,
    formData: formData,
    formIsValid:
      formIsValid ||
      section?.groups.every((g) => g.items.every((i) => i.hidden)),
    submitForm: () => setShowErrors(true),
    onFinish: () => {
      history.push(EventRoutes.Event(eventId));
    },
    saveSectionAction,
  };

  const addGroup =
    isInEditMode && !isLastStep && !isPublished ? (
      <CreateNewGroup
        eventId={eventId}
        sectionId={section?.id}
        onRefresh={onRefresh}
      />
    ) : null;

  useEffect(() => {
    // removes the lastpass icon from any inputs within this element
    // attribute needs to be on input element directly but a Semantic Input does not allow attributes to be passed to the input (it places them on the wrapping div instead)
    wrapperEl.current
      ?.getElementsByTagName("input")
      .forEach((x) => x.setAttribute("data-lpignore", "true"));
  });

  return (
    <section className="step" ref={wrapperEl}>
      <MessageBox
        title="Information"
        icon={InfoIcon}
        className="registration-info"
      >
        Log back in to this portal if you need to update your details at any
        time
      </MessageBox>

      <Form
        disabled={disabled}
        value={formData}
        onChange={(field, data, applyChanges) =>
          dispatch(updateStepFormState({ stepKey: step.key, applyChanges }))
        }
        onValidated={(isValid) => {
          dispatch(
            setStepValidation({
              stepKey: step.key,
              isValid,
            })
          );
        }}
        showErrors={showErrors}
      >
        {section?.groups.map((x, i) => (
          <RegistrationGroup
            key={x.id}
            group={x}
            eventId={eventId}
            participantId={participantId}
            onRefresh={onRefresh}
            isOnLastStep={isLastStep}
            sectionId={section?.id}
          ></RegistrationGroup>
        ))}

        {addGroup}

        <footer className={classNames("step-nav")}>
          {!isFirstStep ? <StepPrevious /> : <div></div>}
          <div>
            {step.hasInputItems ? (
              <React.Fragment>
                {saveSectionAction && <StepSave {...saveProps} />}
                <StepNext {...saveProps} />
              </React.Fragment>
            ) : isLastStep ? (
              <StepNext
                className="nav-next"
                nextLabel="View Event Details"
                {...saveProps}
              />
            ) : null}
          </div>
        </footer>
      </Form>
    </section>
  );
};

export default ProgressStep;
