import React from "react";
import classNames from "classnames";
import { FormBuilder, Form } from "@redriver/cinnamon";
import { formatProgrammeTime } from "modules/helpers";
import moment from "moment";

const ProgrammeActivity = ({
  startTime,
  endTime,
  editing,
  onTimeChange,
  className,
  children,
  ...props
}) => (
  <div {...props} className={classNames("programme-activity", className)}>
    <div className="activity-time">
      {editing ? (
        <FormBuilder
          initialData={{ startTime, endTime }}
          onChange={onTimeChange}
          renderForm={(formProps) => {
            const { startTime: start, endTime: end } = formProps.value;
            return (
              <Form {...formProps} showErrors>
                <Form.Time
                  field="startTime"
                  label="Time From"
                  minTime={end ? moment().startOf("day") : undefined}
                  maxTime={end ? moment(end, "HH:mm") : undefined}
                />
                <Form.Time
                  field="endTime"
                  label="Time To"
                  minTime={start ? moment(start, "HH:mm") : undefined}
                  maxTime={start ? moment().endOf("day") : undefined}
                  disabled={!formProps.value.startTime}
                />
                <Form.Trigger
                  field="startTime"
                  action="clear"
                  target="endTime"
                  match={({ fields }) => !fields.startTime}
                />
              </Form>
            );
          }}
        />
      ) : (
        <React.Fragment>
          {formatProgrammeTime(startTime)}
          {endTime ? ` - ${formatProgrammeTime(endTime)}` : null}
        </React.Fragment>
      )}
    </div>
    <div className="activity-content">{children}</div>
  </div>
);

export default ProgrammeActivity;
