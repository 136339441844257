import React, { useMemo } from "react";
import { Form } from "@redriver/cinnamon";
import {
  GenderTypes,
  RegistrationItemTypes,
  RegistrationItemWidths,
  SocialMediaLinkType,
} from "constants/enums";
import { ColumnGrid } from "components/containers";
import {
  GenderDropdown,
  UserTitleDropdown,
  YesNoRadioGroup,
  CountryDropdown,
  OrganisationTypeAhead,
  RegexInput,
  OutsideMultiSelect,
  ThemeCheckboxGroup,
  CustomErrors,
  YearOfBirthDropdown,
} from "components/forms";
import RegistrationLabel from "./RegistrationLabel";
import RegistrationConclusion from "./RegistrationConclusion";
import RegistrationEventDetails from "./RegistrationEventDetails";
import RegistrationFormField from "./RegistrationFormField";
import {
  socialMediaLinkRegexMapping,
  socialMediaLinkExampleMapping,
  socialMediaLinkPrefillMapping,
} from "constants/regexes";
import { programmeSubThemesLookup } from "modules/lookups";
import { useSelector } from "react-redux";
import {
  getIsEditMode,
  getParticipantIsVirtualAttendance,
  getSectionFormData,
  getSectionInfo,
} from "./selectors";
import EditRegistrationItemModal from "./EditRegistrationItemModal";
import ExtraRegistrationItemContent from "./ExtraRegistrationItemContent";
import classNames from "classnames";
import { FloatingButton } from "components/buttons";
import RegistrationAttendanceDates from "./RegistrationAttendanceDates";
import { ExternalLink } from "components/navigation";
import { ExternalUrls } from "constants/urls";
import { WiltonPark } from "constants/contacts";
import AssistantDetailsItem from "./AssistantDetailsItem";
import { getColsWidth } from "./helpers";
import { Button } from "semantic-ui-react";
import MealRequirements from "./MealRequirements";
import HoldsValidUkVisaItem from "./HoldsValidUkVisaItem";

const RegistrationItem = ({
  item,
  eventId,
  participantId,
  groupId,
  onRefresh,
  minColSize,
  sectionId,
}) => {
  const colsWidth = getColsWidth(item.width, minColSize);
  const isInEditMode = useSelector(getIsEditMode);
  const isVirtualAttendance = useSelector(getParticipantIsVirtualAttendance);

  const editControl = isInEditMode ? (
    <EditItemWrapper>
      <EditRegistrationItemModal
        item={item}
        groupId={groupId}
        eventId={eventId}
        onSubmitted={onRefresh}
      />
    </EditItemWrapper>
  ) : null;

  const gridItemProps = {
    colsWidth,
    className: item.hidden ? "hide-item" : undefined,
  };

  switch (item.type) {
    case RegistrationItemTypes.ContactTitle: {
      return (
        <ColumnGrid.Item {...gridItemProps}>
          <RegistrationFormField item={item} as={UserTitleDropdown} />
          {editControl}
        </ColumnGrid.Item>
      );
    }

    case RegistrationItemTypes.ProfileMobile:
    case RegistrationItemTypes.ProfilePhone:
    case RegistrationItemTypes.ProfileTown:
    case RegistrationItemTypes.ContactFirstName:
    case RegistrationItemTypes.ContactLastName:
    case RegistrationItemTypes.ContactGong:
    case RegistrationItemTypes.ProfileDisplayName:
    case RegistrationItemTypes.ProfileJobTitle:
    case RegistrationItemTypes.TextInput: {
      return (
        <ColumnGrid.Item {...gridItemProps}>
          <RegistrationFormField item={item} as={Form.Input} />
          {editControl}
        </ColumnGrid.Item>
      );
    }
    case RegistrationItemTypes.ProfileCountry: {
      return (
        <ColumnGrid.Item {...gridItemProps}>
          <RegistrationFormField item={item} as={CountryDropdown} />
          {editControl}
        </ColumnGrid.Item>
      );
    }
    case RegistrationItemTypes.ProfileCitizenship:
    case RegistrationItemTypes.ProfileSecondaryCitizenship: {
      return (
        <ColumnGrid.Item {...gridItemProps}>
          <RegistrationFormField
            item={item}
            as={CountryDropdown}
            lookupParams={{
              includePreferNotToSay: true,
            }}
          />
          {editControl}
        </ColumnGrid.Item>
      );
    }
    case RegistrationItemTypes.SpecialRequirements:
    case RegistrationItemTypes.MultiLineTextInput: {
      return (
        <ColumnGrid.Item {...gridItemProps}>
          <RegistrationFormField item={item} as={Form.TextArea} />
          {editControl}
        </ColumnGrid.Item>
      );
    }

    case RegistrationItemTypes.SpecialMealRequirements: {
      return isVirtualAttendance ? null : (
        <ColumnGrid.Item {...gridItemProps}>
          <RegistrationFormField item={item} as={MealRequirements} />
          {editControl}
        </ColumnGrid.Item>
      );
    }

    case RegistrationItemTypes.LiaiseWithAssistant:
    case RegistrationItemTypes.AccommodationRequired:
    case RegistrationItemTypes.TravelBookingLocalTravelAssistance:
    case RegistrationItemTypes.ProfileTwitterPublic:
    case RegistrationItemTypes.ProfileMobilePublic:
    case RegistrationItemTypes.ProfilePhonePublic:
    case RegistrationItemTypes.ProfileEmailPublic:
    case RegistrationItemTypes.YesNoInput: {
      const validateRequired = ({ fields }) => {
        if (fields[item.id] == undefined || fields[item.id] == null) {
          return ["This field is required"];
        }
        return [];
      };
      return (
        <ColumnGrid.Item {...gridItemProps}>
          <div style={{ flexGrow: 1 }}>
            <RegistrationFormField
              item={item}
              as={YesNoRadioGroup}
              showErrors={false}
              className={item.required ? "required" : null}
            />
            {item.required && (
              <CustomErrors
                field={item.id}
                triggerFields={[item.id]}
                validator={validateRequired}
              />
            )}
          </div>
          {editControl}
        </ColumnGrid.Item>
      );
    }

    case RegistrationItemTypes.TravelBookingFlightAssistance: {
      const validateRequired = ({ fields }) => {
        if (fields[item.id] == undefined || fields[item.id] == null) {
          return ["This field is required"];
        }
        return [];
      };
      return (
        <ColumnGrid.Item {...gridItemProps}>
          <div style={{ flexGrow: 1 }}>
            <RegistrationFormField
              item={item}
              as={YesNoRadioGroup}
              showErrors={false}
              className={item.required ? "required" : null}
              extraLabelContent={
                <span className="sub-header">
                  We will put you in touch with our travel agent who will book
                  this for you, within the limitations of our{" "}
                  <ExternalLink href={ExternalUrls.WiltonPark.TravelPolicy}>
                    Travel Policy
                  </ExternalLink>
                  .
                </span>
              }
            />
            {item.required && (
              <CustomErrors
                field={item.id}
                triggerFields={[item.id]}
                validator={validateRequired}
              />
            )}
          </div>
          {editControl}
        </ColumnGrid.Item>
      );
    }

    case RegistrationItemTypes.UkVisaRequired: {
      const validateRequired = ({ fields }) => {
        if (fields[item.id] == undefined || fields[item.id] == null) {
          return ["This field is required"];
        }
        return [];
      };
      return isVirtualAttendance ? null : (
        <ColumnGrid.Item {...gridItemProps}>
          <div style={{ flexGrow: 1 }}>
            <RegistrationFormField
              item={item}
              as={YesNoRadioGroup}
              showErrors={false}
              className={item.required ? "required" : null}
              extraLabelContent={
                <span className="sub-header">
                  Check if you need a UK visa at{" "}
                  <ExternalLink href={ExternalUrls.Gov.CheckUkVisa}>
                    https://www.gov.uk/check-uk-visa
                  </ExternalLink>
                </span>
              }
            />
            {item.required && (
              <CustomErrors
                field={item.id}
                triggerFields={[item.id]}
                validator={validateRequired}
              />
            )}
          </div>
          {editControl}
        </ColumnGrid.Item>
      );
    }

    case RegistrationItemTypes.ValidUkVisaHeld: {
      return (
        <HoldsValidUkVisaItem
          editControl={editControl}
          gridItemProps={gridItemProps}
          item={item}
          isVirtualAttendance={isVirtualAttendance}
          sectionId={sectionId}
        />
      );
    }

    case RegistrationItemTypes.Blank: {
      return <ColumnGrid.Item {...gridItemProps}></ColumnGrid.Item>;
    }

    case RegistrationItemTypes.ContactYearOfBirth: {
      return (
        <ColumnGrid.Item {...gridItemProps}>
          <RegistrationFormField item={item} as={YearOfBirthDropdown} />
          {editControl}
        </ColumnGrid.Item>
      );
    }
    case RegistrationItemTypes.ProfileBiography: {
      return (
        <ColumnGrid.Item {...gridItemProps}>
          <RegistrationFormField item={item} as={Form.TextArea} />
          {editControl}
        </ColumnGrid.Item>
      );
    }

    case RegistrationItemTypes.EventDetails: {
      return (
        <ColumnGrid.Item colsWidth={getColsWidth(RegistrationItemWidths.Full)}>
          <RegistrationEventDetails summary={item.label} eventId={eventId} />
        </ColumnGrid.Item>
      );
    }
    case RegistrationItemTypes.Conclusion: {
      return (
        <ColumnGrid.Item colsWidth={getColsWidth(RegistrationItemWidths.Full)}>
          <RegistrationConclusion item={item} />
        </ColumnGrid.Item>
      );
    }
    case RegistrationItemTypes.FriendsOfWiltonPark: {
      return (
        <ColumnGrid.Item colsWidth={getColsWidth(RegistrationItemWidths.Full)}>
          <div>
            <strong style={{ display: "block" }}>
              Friends of Wilton Park is available for you to join. It is a free
              LinkedIn network available exclusively to current and past Wilton
              Park participants. It is an opportunity for you to gain insider
              insight, intelligence and access into Wilton Park's global network
              of partners and participants.
            </strong>

            <Button
              primary
              compact
              icon="linkedin"
              content={"Join Friends Of Wilton Park"}
              href={ExternalUrls.SocialMedia.FriendsOfWiltonParkLinkedin}
              as={ExternalLink}
              style={{ marginTop: "0.25em" }}
            />
          </div>
        </ColumnGrid.Item>
      );
    }

    case RegistrationItemTypes.ContactGender: {
      return (
        <Form.Object field={item.id} key={item.id + colsWidth}>
          <ColumnGrid.Item {...gridItemProps}>
            <GenderDropdown
              field="gender"
              required={item.required}
              requiredError={`${item.label || "Field"} is required`}
              label={
                <RegistrationLabel
                  label={item.label}
                  subHeader={item.subHeading}
                  infoText={item.infoText}
                  labelPopupContent={
                    isInEditMode ? (
                      <ExtraRegistrationItemContent item={item} />
                    ) : null
                  }
                />
              }
              fluid
            />
            {editControl}
          </ColumnGrid.Item>
          <Form.If
            condition={({ fields }) => fields.gender == GenderTypes.Other}
          >
            <ColumnGrid.Item {...gridItemProps}>
              <Form.Input
                field="other"
                required
                requiredError={`Please specify gender field is required`}
                label="Please specify gender"
                fluid
              />
              {isInEditMode ? (
                <EditItemWrapper>
                  <FloatingButton className="hidden" />
                </EditItemWrapper>
              ) : null}
            </ColumnGrid.Item>
          </Form.If>
        </Form.Object>
      );
    }

    case RegistrationItemTypes.ProfileOrganisation: {
      return (
        <ColumnGrid.Item {...gridItemProps}>
          <RegistrationFormField
            item={item}
            as={OrganisationTypeAhead}
            placeholder=" "
            textField={`${item.id}#Name`}
            searchSuggestions
            allowAdditions
          />
          {editControl}
        </ColumnGrid.Item>
      );
    }

    case RegistrationItemTypes.ProfileTwitter: {
      return (
        <ColumnGrid.Item {...gridItemProps}>
          <RegistrationFormField
            item={item}
            as={RegexInput}
            regexPattern={
              socialMediaLinkRegexMapping[SocialMediaLinkType.Twitter]
            }
            regexFlags="i"
            placeholder="Enter your Twitter profile link"
            regexError={`Link must match the format ${
              socialMediaLinkExampleMapping[SocialMediaLinkType.Twitter]
            }`}
            focusPrefill={
              socialMediaLinkPrefillMapping[SocialMediaLinkType.Twitter]
            }
          />
          {editControl}
        </ColumnGrid.Item>
      );
    }

    case RegistrationItemTypes.ProfileThemes: {
      return (
        <ColumnGrid.Item {...gridItemProps}>
          <RegistrationFormField item={item} as={ThemeCheckboxGroup} />
          {editControl}
        </ColumnGrid.Item>
      );
    }

    case RegistrationItemTypes.ProfileSubThemes: {
      return (
        <ColumnGrid.Item {...gridItemProps}>
          <RegistrationFormField
            item={item}
            as={OutsideMultiSelect}
            lookup={programmeSubThemesLookup}
            className="subtheme-selection"
            placeholder="Please select"
          />
          {editControl}
        </ColumnGrid.Item>
      );
    }

    case RegistrationItemTypes.AttendanceDates: {
      return (
        <ColumnGrid.Item {...gridItemProps}>
          <RegistrationAttendanceDates
            item={item}
            isInEditMode={isInEditMode}
            eventId={eventId}
            participantId={participantId}
          />
          {editControl}
        </ColumnGrid.Item>
      );
    }

    case RegistrationItemTypes.PreferencesFutureDetails: {
      const validateRequired = ({ fields }) => {
        if (fields[item.id] == undefined || fields[item.id] == null) {
          return ["Selecting your preference is required"];
        }
        return [];
      };
      return (
        <ColumnGrid.Item {...gridItemProps}>
          <div>
            <RegistrationFormField
              item={item}
              as={YesNoRadioGroup}
              showErrors={false}
              className={item.required ? "required" : null}
              yesLabelOverride="Yes, I consent to Wilton Park sending me invitations, details about future events and other information that I might be interested in"
              noLabelOverride="No, I do not consent to Wilton Park sending me invitations, details about future events and other information that I might be interested in"
              vertical
              extraLabelContent={
                <span className="sub-header">
                  We will only use your personal information as set out in our{" "}
                  <ExternalLink href={ExternalUrls.WiltonPark.PrivacyPolicy}>
                    Privacy Notice
                  </ExternalLink>
                  , or you can request a copy of it by contacting us directly.
                  If you wish to make any requests relating to the manner in
                  which Wilton Park uses your personal information, please
                  contact our{" "}
                  <ExternalLink
                    href={`mailto:${WiltonPark.DataProtectionOfficer}`}
                  >
                    {WiltonPark.DataProtectionOfficer}
                  </ExternalLink>{" "}
                  or the contact details set out in our Privacy Notice.
                </span>
              }
            />

            {item.required && (
              <CustomErrors
                field={item.id}
                triggerFields={[item.id]}
                validator={validateRequired}
              />
            )}
          </div>
          {editControl}
        </ColumnGrid.Item>
      );
    }

    case RegistrationItemTypes.PreferencesGroupPhoto: {
      const validateRequired = ({ fields }) => {
        if (fields[item.id] == undefined || fields[item.id] == null) {
          return ["Selecting your preference is required"];
        }
        return [];
      };
      return (
        <ColumnGrid.Item {...gridItemProps}>
          <div>
            <RegistrationFormField
              item={item}
              as={YesNoRadioGroup}
              showErrors={false}
              className={item.required ? "required" : null}
              yesLabelOverride="Yes, I am happy to be identified in the group photograph"
              noLabelOverride="No, I do not wish to be identified in the group photograph"
              vertical
            />
            {item.required && (
              <CustomErrors
                field={item.id}
                triggerFields={[item.id]}
                validator={validateRequired}
              />
            )}
          </div>
          {editControl}
        </ColumnGrid.Item>
      );
    }
    case RegistrationItemTypes.AssistantDetails: {
      return (
        <ColumnGrid.Item {...gridItemProps} style={{ flexDirection: "column" }}>
          <AssistantDetailsItem
            item={item}
            isInEditMode={isInEditMode}
            minColSize={minColSize}
          >
            {editControl}
          </AssistantDetailsItem>
        </ColumnGrid.Item>
      );
    }

    case RegistrationItemTypes.TravelBookingInfo: // deprecated, now displayed under flight booking sub-heading
    default:
      return null;
  }
};

const EditItemWrapper = ({ children, className }) => (
  <div className={classNames("edit-item", className)}>{children}</div>
);

export default RegistrationItem;
