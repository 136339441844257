import React from "react";
import classNames from "classnames";
import { colord } from "colord";
import { Label, Popup } from "semantic-ui-react";
import { ContactSearchAccessibility } from "constants/enums";

const TagLabel = ({
  className,
  colour,
  accessibility,
  description,
  ...props
}) => {
  const isPublic = accessibility === ContactSearchAccessibility.Public;
  const invertedColour =
    !!colour && colord(colour).invert().isDark() ? "#000000" : "#ffffff";

  const label = (
    <Label
      {...props}
      className={classNames("tag-label", { public: isPublic }, className)}
      style={{
        backgroundColor: isPublic ? colour : "#ffffff",
        color: isPublic ? invertedColour : colour,
        borderColor: colour,
      }}
    />
  );

  if (!description) return label;

  return (
    <Popup
      inverted
      content={description}
      trigger={label}
      position="top center"
    />
  );
};

export default TagLabel;
