import React from "react";
import { Form } from "@redriver/cinnamon";
import { mealRequirementOptions } from "./lookups";
import { MealRequirementOption } from "constants/enums";

const MealRequirements = ({ field, required, label, disabled }) => {
  return (
    <div>
      <Form.Object field={field} disabled={disabled}>
        <Form.Dropdown
          field="requirements"
          label={label}
          required={required}
          lookup={mealRequirementOptions}
          multiple
          fluid
        />
        <Form.If
          condition={({ fields }) =>
            Array.isArray(fields.requirements) &&
            fields.requirements.some((x) => x == MealRequirementOption.Other)
          }
        >
          <div style={{ paddingTop: "0.5em" }}>
            <Form.TextArea
              field="other"
              label="Other meal requirements"
              fluid
              required
            />
          </div>
        </Form.If>
        <Form.Trigger
          field="requirements"
          event="onMatch"
          match={({ fields }) =>
            !Array.isArray(fields.requirements) ||
            !fields.requirements.some((x) => x == MealRequirementOption.Other)
          }
          target="other"
          action="clear"
        />
      </Form.Object>
    </div>
  );
};

export default MealRequirements;
